/* Asegúrate de que el body y html no generen scroll */
html,
body {
  margin: 0;
  padding: 0;
  /* funcion SCROLL*/
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

/* Estilos para el contenedor de las rutas animadas */
.route-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Evita el scroll en el contenedor */
}

/* Asegúrate de que los elementos hijos no generen scroll */
.route-container > * {
    margin: 0;
    padding: 0;
    overflow: hidden; /* Evita el scroll en los elementos hijos */
    width: 100%;
    height: 100%;
    box-sizing: border-box; /* Incluye padding y borde en el tamaño total del elemento */
}

/* Aplicar box-sizing a todos los elementos */
* {
    box-sizing: border-box;
}
/*==============================================*/

/* Slide forward (from right to left) */
.slide-forward-enter {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .slide-forward-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms ease-in, opacity 300ms ease-in;
  }
  
  .slide-forward-exit {
    transform: translateX(0);
    opacity: 1;
  }
  
  .slide-forward-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 300ms ease-in, opacity 300ms ease-in;
  }
  
  /* Slide backward (from left to right) */
  .slide-backward-enter {
    transform: translateX(-100%);
    opacity: 0;
  }
  
  .slide-backward-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms ease-in, opacity 300ms ease-in;
  }
  
  .slide-backward-exit {
    transform: translateX(0);
    opacity: 1;
  }
  
  .slide-backward-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 300ms ease-in, opacity 300ms ease-in;
  }

